import * as Types from '@sal-solution/types/types/fish-shop/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ShopsQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ShopsQuery = { __typename?: 'Query', shops?: Array<{ __typename?: 'Shop', name: string, slug?: string | null, city?: string | null, status?: string | null } | null> | null };


export const ShopsDocument = gql`
    query shops($search: String) {
  shops(search: $search) {
    name
    slug
    city
    status
  }
}
    `;

/**
 * __useShopsQuery__
 *
 * To run a query within a React component, call `useShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useShopsQuery(baseOptions?: Apollo.QueryHookOptions<ShopsQuery, ShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopsQuery, ShopsQueryVariables>(ShopsDocument, options);
      }
export function useShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopsQuery, ShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopsQuery, ShopsQueryVariables>(ShopsDocument, options);
        }
export function useShopsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ShopsQuery, ShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ShopsQuery, ShopsQueryVariables>(ShopsDocument, options);
        }
export type ShopsQueryHookResult = ReturnType<typeof useShopsQuery>;
export type ShopsLazyQueryHookResult = ReturnType<typeof useShopsLazyQuery>;
export type ShopsSuspenseQueryHookResult = ReturnType<typeof useShopsSuspenseQuery>;
export type ShopsQueryResult = Apollo.QueryResult<ShopsQuery, ShopsQueryVariables>;