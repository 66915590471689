'use client';
/** @jsxImportSource @emotion/react */

import 'twin.macro';

import {
  HeroSection,
  PageLayout,
  Table,
  Text,
  WhatsAppButton,
} from '@sal-solution/ui-web';
import { useParams } from 'next/navigation';
import { FC } from 'react';
import { useFindOrderQuery } from './graphql/findOrder.generated';

export const MyOrderPage: FC = () => {
  const params = useParams();

  const { data, loading, error } = useFindOrderQuery({
    variables: {
      id: params.orderId as string,
    },
    skip: !params.orderId,
  });

  const order = data?.order;
  const deliveryDate = new Date(Number(order?.deliveryDate));
  return (
    <PageLayout>
      <HeroSection
        bottomVariant="Waves"
        tw="relative flex bg-blue-50 flex-col min-h-[30vh]"
      >
        <div tw="flex flex-col px-4 w-full">
          {loading && <Text>Loading...</Text>}
          {error && <Text tw="text-red-500">{error.message}</Text>}
          <Text variant="title">Status: {order?.status}</Text>
          <Text variant="subTitle">
            Verwachte lever datum: {deliveryDate.toLocaleDateString()}
          </Text>
          <Table
            head={['Product', 'Price']}
            rows={
              order?.products?.map((product) => [
                product?.name as string,
                product?.quantity as string,
              ]) || []
            }
          />
          <WhatsAppButton tw="absolute bottom-4" logo={'/WALogo.png'} />
        </div>
      </HeroSection>
    </PageLayout>
  );
};
