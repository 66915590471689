'use client';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { ProductCard } from '@/components/ProductCard/ProductCard';
import { useCart } from '@/context';
import { useShopSlug } from '@/hooks/useShopSlug';
import { Button, PageLayout, Section, Text } from '@sal-solution/ui-web';
import Link from 'next/link';
import { useFindShopQuery } from '../graphql/findShop.generated';
import { useShopProductsQuery } from '../graphql/findShopProducts.generated';

export const ShopPage = () => {
  const slug = useShopSlug();
  const { carts } = useCart();
  const foundShop = useFindShopQuery({
    variables: {
      slug: slug as string,
    },
    skip: !slug,
  });
  const shop = foundShop.data?.shop;
  const cart = carts.find((cart) => cart.shopId === shop?._id);

  const foundProducts = useShopProductsQuery({
    variables: {
      shopId: shop?._id as string,
    },
    skip: !shop,
  });

  const products = foundProducts.data?.findShopProducts;

  const loading = foundShop.loading || foundProducts.loading;
  const error = foundShop.error || foundProducts.error;

  return (
    <PageLayout>
      <Section tw="flex flex-col space-y-4 justify-start px-4">
        {loading && <Text>Loading...</Text>}
        {error && <Text>Error: {error.message}</Text>}
        <div tw="grid gap-4 lg:grid-cols-2">
          {products?.map((product) => (
            <ProductCard
              key={product?._id}
              as={Link}
              href={`/product/${product?.slug}`}
              {...{
                name: product?.name,
                description: product?.description,
                slug: product?.slug,
                _id: product?._id,
                image: product?.image,
                price: product?.price,
                unit: product?.unit,
              }}
            />
          ))}
        </div>
        {cart && (
          <div tw="flex w-full bottom-0 left-0 fixed py-4 px-2">
            <Button as={Link} href={`/winkelmand`} tw="w-full ">
              Naar de winkelmand
            </Button>
          </div>
        )}
      </Section>
    </PageLayout>
  );
};
