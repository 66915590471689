import(/* webpackMode: "eager" */ "/var/www/vhosts/noordzeekaviaar.be/httpdocs/apps/fish-shop/modules/CartPage/CartPage.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/noordzeekaviaar.be/httpdocs/apps/fish-shop/modules/ConfirmationPage/ConfirmationPage.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/noordzeekaviaar.be/httpdocs/apps/fish-shop/modules/HomePage/HomePage.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/noordzeekaviaar.be/httpdocs/apps/fish-shop/modules/MyOrderPage/MyOrderPage.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/noordzeekaviaar.be/httpdocs/apps/fish-shop/modules/OrderPage/OrderPage.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/noordzeekaviaar.be/httpdocs/apps/fish-shop/modules/ProductPage/ProductPage.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/noordzeekaviaar.be/httpdocs/apps/fish-shop/modules/ShopPage/ShopPage.tsx");
