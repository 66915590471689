'use client';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { useCart } from '@/context';
import { useShopSlug } from '@/hooks/useShopSlug';
import {
  Button,
  CartItem,
  PageLayout,
  Section,
  Text,
} from '@sal-solution/ui-web';
import { toCurrency } from '@sal-solution/utils';
import Link from 'next/link';
import { useFindShopQuery } from '../graphql/findShop.generated';
import { useShopProductsQuery } from '../graphql/findShopProducts.generated';

export const CartPage = () => {
  const slug = useShopSlug();

  const foundShop = useFindShopQuery({
    variables: {
      slug: slug as string,
    },
    skip: !slug,
  });
  const productsByShop = useShopProductsQuery({
    variables: {
      shopId: foundShop.data?.shop?._id as string,
    },
    skip: !foundShop.data?.shop?._id,
  });

  const shop = foundShop.data?.shop;

  const { findCart, removeItem } = useCart();

  const cart = findCart(shop?._id as string);

  const cartProducts = cart?.products.map((product) => {
    const foundProduct = productsByShop.data?.findShopProducts?.find(
      (shopProduct) => shopProduct?._id === product?._id
    );

    return {
      qty: product.qty,
      ...foundProduct,
    };
  });

  const isLoading = foundShop.loading || productsByShop.loading;
  const isError = foundShop.error || productsByShop.error;

  return (
    <PageLayout>
      <Section tw="flex-col space-y-4 px-4 justify-start">
        {isLoading && <Text>Loading...</Text>}
        {isError && <Text>Error: {isError.message}</Text>}
        <div tw="text-left w-full">
          <Text variant="title">Uw winkelmand</Text>
          <Text>Hieronder vindt u uw bestellingen</Text>
        </div>
        <ul tw="w-full">
          {cartProducts?.map((product) => (
            <CartItem
              as="li"
              onRemove={() =>
                removeItem(product?._id as string, shop?._id as string)
              }
              key={product?._id}
              name={product?.name}
              image={product?.image}
              quantity={`${product?.qty}${product?.unit}`}
              total={
                product?.price &&
                product?.qty &&
                toCurrency(Number(product?.price) * Number(product?.qty))
              }
            >
              <div tw="col-start-2 row-start-2 col-span-2 lg:col-span-1">
                <Button
                  onClick={() =>
                    removeItem(product?._id as string, shop?._id as string)
                  }
                >
                  Verwijder
                </Button>
              </div>
            </CartItem>
          )) || (
            <Text as="li">Voeg een product toe om te kunnen bestellen</Text>
          )}
        </ul>
        <div tw="flex flex-col w-full fixed bottom-4 left-0 px-4 bg-white space-y-4 lg:(relative flex-row bottom-0 left-0 px-0 space-x-4 space-y-0)">
          <Button
            disabled={!cartProducts?.length}
            as={Link}
            href={`/bestellen?shopId=${shop?._id}`}
            tw="w-full lg:w-fit"
          >
            Bestellen
          </Button>
          <Button as={Link} href={`/`} variant="light" tw="w-full lg:w-fit">
            Terug naar de winkel
          </Button>
        </div>
      </Section>
    </PageLayout>
  );
};
