'use client';
/** @jsxImportSource @emotion/react */

import 'twin.macro';

import {
  Card,
  HeroSection,
  PageLayout,
  SmartForm,
  SmartFormElement,
  Text,
} from '@sal-solution/ui-web';
import { FC } from 'react';
import tw from 'twin.macro';
import { useShopsQuery } from './graphql/shops.generated';
import Link from 'next/link';

const statusTexts: Record<string, string> = {
  success: 'Open',
  warning: 'Sluit binnenkort',
  error: 'Gesloten',
};

export const HomePage: FC = () => {
  const { refetch, loading, data, error } = useShopsQuery({
    variables: { search: 'none' },
  });

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.target as HTMLFormElement);
    const search = formData.get('search') as string;

    if (search) {
      await refetch({ search });
    }
  };
  return (
    <PageLayout>
      <HeroSection
        tw="bg-blue-50"
        bottomFill="Waves"
        fill="#fff"
        innerStyle={[tw`px-4 flex flex-col space-y-4 max-w-lg mx-auto`]}
      >
        {loading && <Text>Loading...</Text>}
        {error && <Text tw="text-red-500">Error: {error.message}</Text>}
        <SmartForm
          tw="w-full"
          elements={[searchElements]}
          onSubmit={onSubmit}
        />
        <div tw="grid w-full">
          {data?.shops?.map((shop) => (
            <Card as={Link} href={`/${shop?.slug}`} key={shop?.slug}>
              <Text variant="subTitle">
                {shop?.name}{' '}
                <Text as="span">({statusTexts[shop?.status || 'error']})</Text>
              </Text>
              <Text>{shop?.city}</Text>
            </Card>
          ))}
        </div>
      </HeroSection>
    </PageLayout>
  );
};

const searchElements: SmartFormElement[] = [
  {
    type: 'input',
    name: 'search',
    label: 'Search',
    placeholder: 'Search',
  },
];
