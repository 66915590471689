'use client';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { Card, Text } from '@sal-solution/ui-web';
import Image from 'next/image';
import { FC, PropsWithChildren } from 'react';
import { shortenString, toCurrency } from '@sal-solution/utils';

type ProductCardProps = {
  _id?: string | null;
  slug?: string | null;
  image?: string | null;
  name?: string | null;
  price?: number | null;
  unit?: string | null;
  description?: string | null;
} & React.ComponentProps<typeof Card>;

export const ProductCard: FC<PropsWithChildren<ProductCardProps>> = ({
  name,
  description,
  slug,
  _id,
  image,
  price,
  unit,
  children,
  ...rest
}) => {
  return (
    <Card key={_id} tw="w-full grid grid-cols-3 gap-4" {...rest}>
      {image && (
        <Image
          tw="row-start-1 row-end-3 w-full object-cover object-center rounded max-h-[150px]"
          src={image as string}
          alt={name as string}
          width={150}
          height={150}
        />
      )}
      <Text variant="subTitle" tw="col-start-2 col-span-2 lg:col-span-2">
        {name}
      </Text>

      {description && (
        <Text tw="col-start-2 row-start-2 col-span-2 lg:col-span-2">
          {price && (
            <span>
              <b tw="font-bold">{toCurrency(price)}</b> per{' '}
              <b tw="font-bold">{unit}</b>
              <br />
            </span>
          )}
          {shortenString(description, 75)}
        </Text>
      )}
      {children}
    </Card>
  );
};
