import * as Types from '@sal-solution/types/types/fish-shop/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ShopProductsQueryVariables = Types.Exact<{
  shopId: Types.Scalars['ID']['input'];
}>;


export type ShopProductsQuery = { __typename?: 'Query', findShopProducts?: Array<{ __typename?: 'Product', _id: string, slug?: string | null, image?: string | null, description?: string | null, name: string, unit?: string | null, quantity?: string | null, price?: number | null } | null> | null };


export const ShopProductsDocument = gql`
    query shopProducts($shopId: ID!) {
  findShopProducts(shopId: $shopId) {
    _id
    slug
    image
    description
    name
    unit
    quantity
    price
  }
}
    `;

/**
 * __useShopProductsQuery__
 *
 * To run a query within a React component, call `useShopProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopProductsQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useShopProductsQuery(baseOptions: Apollo.QueryHookOptions<ShopProductsQuery, ShopProductsQueryVariables> & ({ variables: ShopProductsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopProductsQuery, ShopProductsQueryVariables>(ShopProductsDocument, options);
      }
export function useShopProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopProductsQuery, ShopProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopProductsQuery, ShopProductsQueryVariables>(ShopProductsDocument, options);
        }
export function useShopProductsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ShopProductsQuery, ShopProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ShopProductsQuery, ShopProductsQueryVariables>(ShopProductsDocument, options);
        }
export type ShopProductsQueryHookResult = ReturnType<typeof useShopProductsQuery>;
export type ShopProductsLazyQueryHookResult = ReturnType<typeof useShopProductsLazyQuery>;
export type ShopProductsSuspenseQueryHookResult = ReturnType<typeof useShopProductsSuspenseQuery>;
export type ShopProductsQueryResult = Apollo.QueryResult<ShopProductsQuery, ShopProductsQueryVariables>;