'use client';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { useCart } from '@/context';
import { useShopSlug } from '@/hooks/useShopSlug';
import { PlusIcon } from '@heroicons/react/20/solid';
import {
  Button,
  Carousel,
  Input,
  PageLayout,
  Section,
  Text,
} from '@sal-solution/ui-web';
import { toCurrency } from '@sal-solution/utils';
import Link from 'next/link';
import { useParams, useRouter, useSearchParams } from 'next/navigation';
import { toast } from 'react-toastify';
import { useFindProductQuery } from '../graphql/findProduct.generated';
import { useFindShopQuery } from '../graphql/findShop.generated';
import { FC } from 'react';

export const ProductPage = () => {
  const { addItem, carts } = useCart();
  const params = useParams();
  const slug = useShopSlug();

  const searchQuery = useSearchParams();
  const productSlug = searchQuery.get('slug') || (params.product as string);
  const foundProduct = useFindProductQuery({
    variables: {
      slug: productSlug as string,
      shopSlug: slug as string,
    },
    skip: !productSlug,
  });

  const foundShop = useFindShopQuery({
    variables: {
      slug: slug as string,
    },
    skip: !slug,
  });

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (foundShop.data?.shop?.status === 'error') return;

    const form = new FormData(event.currentTarget);
    const qty = form.get('qty') as string;
    if (!qty) return;

    addItem(
      foundProduct.data?.product?._id as string,
      qty,
      foundShop.data?.shop?._id as string
    );

    toast.success('Product toegevoegd aan winkelmand');
  };

  const cart = carts.find((cart) => cart.shopId === foundShop.data?.shop?._id);

  const product = foundProduct.data?.product;
  const isLoading = foundProduct.loading || foundShop.loading;
  const isError = foundProduct.error || foundShop.error;
  return (
    <PageLayout>
      <Section tw="flex flex-col justify-start px-4 py-0 lg:(py-0 pb-12) 2xl:(py-0)">
        <div tw="w-full flex flex-col">
          {/* {isLoading && <Text>Loading...</Text>} */}
          {isError && <Text tw="text-red-500">{isError.message}</Text>}
        </div>

        <div tw="flex w-full flex-col lg:(space-x-4 flex-row justify-between)">
          <SkeletonOrItem
            item={
              <Carousel
                variant="secondary"
                images={product?.images as string[]}
              />
            }
            skeleton={<div tw="skeleton w-full h-[300px] lg:h-full my-2"></div>}
            isLoading={isLoading}
          />

          <div tw="w-full space-y-4 mb-4 lg:mb-0 lg:max-w-[500px]">
            <div tw="flex flex-col lg:space-y-4">
              <SkeletonOrItem
                item={
                  <Text variant="title" tw="text-4xl">
                    {product?.name}
                  </Text>
                }
                skeleton={<div tw="skeleton w-full h-8 my-2"></div>}
                isLoading={isLoading}
              />

              <SkeletonOrItem
                item={
                  product?.price && (
                    <Text variant="subTitle">
                      {toCurrency(product?.price)} per {product?.unit}
                    </Text>
                  )
                }
                skeleton={<div tw="skeleton w-full h-6 mt-2"></div>}
                isLoading={isLoading}
              />
            </div>
            <SkeletonOrItem
              item={<Text>{product?.description}</Text>}
              skeleton={<div tw="skeleton w-full h-20 my-2"></div>}
              isLoading={isLoading}
            />
            <SkeletonOrItem
              item={
                product?.features && (
                  <ul tw="pl-4 ">
                    {product?.features?.map((feature) => (
                      <Text as="li" tw="list-disc" key={feature}>
                        {feature}
                      </Text>
                    ))}
                  </ul>
                )
              }
              skeleton={<div tw="skeleton w-32 h-44 my-2"></div>}
              isLoading={isLoading}
            />
            <form
              onSubmit={onSubmit}
              tw="flex flex-col items-center sticky bottom-4 left-0 w-full bg-white  space-y-4 lg:(relative left-0 bottom-0 px-0)"
            >
              <div tw="flex space-x-4 w-full items-end">
                <Input
                  required
                  type="number"
                  label={` Hoe veel ${product?.unit || 'KG'} wilt u bestellen?`}
                  name="qty"
                  placeholder={`10${product?.unit || 'KG'}`}
                />
                <Button variant="success" tw="flex-1 lg:flex-none">
                  <PlusIcon tw="w-6 h-6" />
                </Button>
              </div>

              <div tw="flex space-x-4 w-full">
                <Button variant="light" as={Link} href={`/`} tw="flex-1">
                  Terug
                </Button>

                <Button
                  disabled={isLoading || !cart || cart?.products.length === 0}
                  as={Link}
                  href={`/winkelmand`}
                  tw="flex-1 w-full"
                >
                  Naar Bestellen{' '}
                  {cart &&
                    cart?.products.length > 0 &&
                    `(${cart?.products.length})`}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Section>
    </PageLayout>
  );
};

type SkeletonProps = {
  isLoading: boolean;
  item?: JSX.Element | null | 0;
  skeleton: JSX.Element;
};

const SkeletonOrItem: FC<SkeletonProps> = ({ isLoading, item, skeleton }) => {
  if (isLoading) {
    return skeleton;
  }

  if (!item) {
    return;
  }

  return item;
};

{
  /* <Product
  onClick={onClick}
  name={product?.name}
  description={
    product?.description 
  }
  features={product?.features as string[]}
  image={product?.image 
  images={(product?.images as string[])}
  WALogo={WALogo.src}
  tel={foundShop.data?.shop?.phone as string}
/> */
}
