'use client';
/** @jsxImportSource @emotion/react */

import 'twin.macro';

import { useCart } from '@/context';
import { useShopSlug } from '@/hooks/useShopSlug';
import { OrderInput } from '@sal-solution/types/types/fish-shop/types';
import {
  Button,
  PageLayout,
  Section,
  SmartForm,
  SmartFormElement,
  Text,
} from '@sal-solution/ui-web';
import Link from 'next/link';
import { useRouter, useSearchParams } from 'next/navigation';
import React from 'react';
import { toast } from 'react-toastify';
import { useCreateOrderMutation } from './graphql/createOrder.generated';

export const OrderPage = () => {
  const { carts, clearCart } = useCart();
  const slug = useShopSlug();

  const routes = useRouter();
  const searchQuery = useSearchParams();
  const shopId = searchQuery.get('shopId');

  const cart = carts.find((cart) => cart.shopId === shopId);

  const [createOrder, { loading, error }] = useCreateOrderMutation();
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log(data);
    if (!shopId || !cart) {
      if (!cart) toast.error('U heeft geen producten in uw winkelmandje');
      return console.log('error - no shopId found');
    }
    const orderProducts = cart?.products.map((product) => {
      return {
        _id: product._id,
        quantity: product.qty,
      };
    });
    const order: OrderInput = {
      customer: {
        name: data.get('name') as string,
        email: data.get('email') as string,
        phone: data.get('phone') as string,
        street: data.get('street') as string,
        houseNumber: data.get('houseNumber') as string,
        postalCode: data.get('postalCode') as string,
        city: data.get('city') as string,
      },
      products: orderProducts,
      comment: data.get('comment') as string,
      shopId: shopId as string,
    };

    const response = await createOrder({
      variables: {
        order,
      },
    });
    if (response.data?.createOrder?.shopId) {
      clearCart(shopId);
      routes.push(`/bevestiging`);
    } else console.log('error');
  };

  return (
    <PageLayout>
      <Section tw="px-4 flex flex-col">
        {loading && <Text>Loading</Text>}
        {error?.message && <Text tw="text-red-500">{error.message}</Text>}
        <SmartForm
          tw="w-full h-full max-w-screen-md mx-auto"
          onSubmit={onSubmit}
          loading={loading}
          elements={[userElements, addressElements]}
        />
        <Button
          as={Link}
          href={`/${slug}/winkelmand`}
          tw="mt-4 w-full lg:hidden"
          variant="light"
        >
          Terug naar winkelmand
        </Button>
      </Section>
    </PageLayout>
  );
};

const userElements: SmartFormElement[] = [
  {
    type: 'input',
    name: 'name',
    label: 'Naam',
    placeholder: 'Uw naam',
    required: true,
  },
  {
    type: 'input',
    name: 'email',
    label: 'Email',
    placeholder: 'Uw email',
    required: true,
  },
  {
    type: 'input',
    name: 'phone',
    label: 'Telefoonnummer',
    placeholder: 'Uw telefoonnummer',
    required: true,
  },
  // {
  //   type: 'input',
  //   name: 'quantity',
  //   label: 'Hoe veel KG wilt u bestellen?',
  //   placeholder: 'Uw gewicht',
  //   required: true,
  // },
  {
    type: 'textArea',
    name: 'comment',
    label: 'Bericht',
    placeholder: 'Uw bericht',
  },
];

const addressElements: SmartFormElement[] = [
  {
    type: 'input',
    name: 'street',
    label: 'Straat',
    placeholder: 'Uw straat',
    required: true,
  },
  {
    type: 'input',
    name: 'houseNumber',
    label: 'Huisnummer',
    placeholder: 'Uw huisnummer',
    required: true,
  },
  {
    type: 'input',
    name: 'postalCode',
    label: 'Postcode',
    placeholder: 'Uw postcode',
    required: true,
  },
  {
    type: 'input',
    name: 'city',
    label: 'Plaats',
    placeholder: 'Uw plaats',
    required: true,
  },
];
